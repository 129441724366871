import React, { useMemo } from 'react';
import { Box, Container, Flex, Text, Icon } from '@qga/roo-ui/components';
import { OptimizelyFeature, useFeature } from '@optimizely/react-sdk';
import PageBlock from 'components/PageBlock';
import { rem } from 'polished';
import { useSelector } from 'react-redux';
import { getPathName } from 'store/router/routerSelectors';
import { qantasBusinessRewards, qantasQff } from '@qga/roo-ui/logos';
import { DisclaimerItemLogo, DisclaimerText, DisclaimerLink, TripAdvisorLogo } from 'components/Footer/DisclaimerItems';
import { useDataLayer } from 'hooks/useDataLayer';
import { getCampaignTermsAndConditions } from 'store/campaign/campaignSelectors';
import TravelInsuranceDisclaimer from './TravelInsuranceDisclaimer';
import {
  TRIP_ADVISOR_IMG_BRANDING_URL,
  QCOM_TERMS_AND_CONDITIONS_URL,
  POINTS_EARN_ENABLED,
  TRAVEL_INSURANCE_CROSS_SELL_ENABLED,
  AIRBNB_LANDING_PAGE_URL,
  HOTELS_URL,
  HOTELS_BASE_URL,
  HOTEL_FAQ_URL,
} from 'config';
import Markup from 'components/Markup';

interface DisclaimerSection {
  id: string;
  logo?: JSX.Element;
  items: (JSX.Element | null | boolean)[];
}

const Disclaimer = () => {
  const { emitInteractionEvent } = useDataLayer();
  const campaignTermsAndConditions = useSelector(getCampaignTermsAndConditions);
  const pathName = useSelector(getPathName);
  const isConfirmationPage = pathName?.startsWith('/bookings');

  const [isEnabled, variables] = useFeature('qantas-hotels-blue-banner-message', { autoUpdate: true });
  const blueBannerCampaignDisclaimer = isEnabled ? variables.termsAndConditions : null;
  const blueBannerAdditionalDisclaimer = isEnabled ? variables.additionalTermsAndConditions : null;
  const replaceQffFinalTerms = variables.replaceQffFinalTerms;
  const showBlueBannerTermsInQffFinalTerms = isEnabled && replaceQffFinalTerms;
  const showBlueBannerDisclaimer = isEnabled && !replaceQffFinalTerms;

  const sections = useMemo(() => {
    const qffDisclaimer = (
      <DisclaimerText data-testid="qff-disclaimer-text">
        You must be a Qantas Frequent Flyer member to earn and redeem points. Membership and points are subject to the Qantas Frequent Flyer
        program terms and conditions.
      </DisclaimerText>
    );

    const qffTerms = (
      <DisclaimerText data-testid="qff-terms-text">
        Qantas Frequent Flyer members will earn 3 Qantas Points per A$1 spent unless otherwise specified, for hotel stays booked through
        qantas.com/hotels, except Classic Hotel Rewards and Airbnb bookings. Points Club members will earn 25% more Qantas Points, and
        Points Club Plus members will earn 50% more Qantas Points. Qantas Points will be credited to your account at least 8 weeks after
        check-out. Qantas Points can be earned on cash only purchases and the cash component of Points Plus Pay purchases on eligible
        bookings and will not be earned on cancelled or refunded bookings. Qantas Points may be earned by the member in whose name the
        booking is made. Members will not be able to earn points on additional charges paid to the accommodation provider for extras
        (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable).
      </DisclaimerText>
    );

    const qffDepositPay = (
      <DisclaimerText data-testid="qff-deposit-pay-text">
        Deposit Pay is available on selected properties that offer a free cancellation window that is 21 days or more in the future from the
        date of booking.
      </DisclaimerText>
    );

    const qffFaqs = (
      <DisclaimerText data-testid="qff-faqs-text">
        Visit our{' '}
        <DisclaimerLink
          data-testid="qff-faqs-link"
          href={HOTEL_FAQ_URL}
          onClick={() => emitInteractionEvent({ type: 'FAQ', value: 'View FAQ Page Link Selected' })}
        >
          {' '}
          FAQ page{' '}
        </DisclaimerLink>{' '}
        for more.
      </DisclaimerText>
    );

    const qffPointsPay = (
      <DisclaimerText data-testid="qff-points-pay-text">
        * Qantas Frequent Flyer members can redeem Qantas Points when booking hotel accommodation through qantas.com/hotels or holiday
        packages through qantas.com/holidays, using Points Plus Pay. Members cannot redeem points for additional charges paid to the hotel
        for extras (including cots, breakfasts and other incidentals) on check-in or check-out (as applicable). Points Plus Pay allows you
        to choose the number of Qantas Points you redeem above the specified minimum level of 5,000 and pay for the remainder of the booking
        value with an Accepted Payment Card (including VISA, MasterCard or American Express). Points Plus Pay is not available for Classic
        Hotel Rewards.{' '}
        <DisclaimerLink
          data-testid="pointsPlusPayViewFullTermsAndConditionsLink"
          href={`${HOTELS_URL}/hotels-and-airbnb-terms-and-conditions`}
          onClick={() =>
            emitInteractionEvent({ type: 'Points Plus Pay Conditions', value: 'View Full Terms and Conditions Link Selected' })
          }
        >
          View full terms and conditions here
        </DisclaimerLink>
      </DisclaimerText>
    );

    const qffBonusPoints = (
      <DisclaimerText data-testid="qff-bonus-points-text">
        ++ 500 bonus Qantas Points will be awarded to Qantas Frequent Flyer members who make their first Airbnb booking. Qantas Frequent
        Flyer members will earn 1 Qantas Point per A$1 value for all Airbnb stays booked through{' '}
        <DisclaimerLink
          data-testid="airbnbHomepageLink"
          href={AIRBNB_LANDING_PAGE_URL}
          onClick={() => emitInteractionEvent({ type: 'Terms and Conditions', value: 'AirBnb Selected' })}
        >
          qantas.com/airbnb
        </DisclaimerLink>
        .
      </DisclaimerText>
    );

    const savingsDisclaimer = (
      <DisclaimerText data-testid="savings-disclaimer-text">
        ~ Saving is off the hotel&apos;s generally available rate for the same property, room type, days, inclusions and conditions.
      </DisclaimerText>
    );

    // additional disclaimer for out of the box campaigns such as the WA Triple Points Campaign
    const localCampaignDisclaimer = (
      <OptimizelyFeature feature="qantas-hotels-campaign-terms">
        {(isEnabled, variables) =>
          isEnabled ? (
            <DisclaimerText data-testid="local-campaign-disclaimer-alternative-text">{variables.disclaimer}</DisclaimerText>
          ) : null
        }
      </OptimizelyFeature>
    );

    const yieldifyCampaignDisclaimer = (
      <OptimizelyFeature feature="qantas-hotels-yieldify-campaign">
        {(isEnabled, variables) => {
          if (isEnabled && variables.disclaimer) {
            return <DisclaimerText data-testid="yieldify-campaign-disclaimer-text">{variables.disclaimer}</DisclaimerText>;
          }
          return null;
        }}
      </OptimizelyFeature>
    );

    const qbrDisclaimer = (
      <DisclaimerText data-testid="qbr-disclaimer-text">
        ** A business must be a Qantas Business Rewards Member to earn Qantas Points for business. Businesses earn 1 Qantas Point per AU$1
        spent on eligible Qantas Hotels worldwide booked through &nbsp;
        <DisclaimerLink data-testid="qantasHotelLink" href={`${HOTELS_BASE_URL}/hotels`}>
          qantas.com/hotels
        </DisclaimerLink>
        . Eligible Qantas hotels excludes Classic Hotel Rewards and Airbnb bookings. The Qantas Business Rewards Member&apos;s ABN must be
        included at the time of booking. Qantas Points are not earned on any amounts payable directly to the hotel. &nbsp;
        <DisclaimerLink
          data-testid="businessRewardConditionApplyLink"
          href={QCOM_TERMS_AND_CONDITIONS_URL}
          onClick={() => emitInteractionEvent({ type: 'Business Rewards Conditions', value: 'Conditions Apply Link Selected' })}
        >
          Conditions apply
        </DisclaimerLink>
      </DisclaimerText>
    );

    const travelInsuranceTerms = TRAVEL_INSURANCE_CROSS_SELL_ENABLED
      ? isConfirmationPage && (
          <Flex>
            <TravelInsuranceDisclaimer />
          </Flex>
        )
      : null;

    const feesDisclaimer = (
      <DisclaimerText data-testid="fees-disclaimer-text">
        † Includes fee payable in local currency direct to hotel. Prices in AUD are approx and based on today&#39;s exchange rate.
      </DisclaimerText>
    );

    const descriptionAndRatingsDisclaimer = (
      <DisclaimerText data-testid="description-ratings-disclaimer-text">
        Descriptions and ratings featured are based on information provided by hotels and/or other service providers, distinguished
        accordingly. Ratings are either self-assigned by the service provider or obtained through Expedia (displayed as circles{' '}
        <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="circle" color="brightSun" size={12} mb={rem('1px')} />
        ), or by independent third parties (displayed as stars <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />
        <Icon name="star" color="brightSun" size={12} mb={rem('1px')} />) Any facilities shown as included are subject to change by the
        service provider. Qantas does not guarantee any particular standard or class of accommodation or other service provided by a third
        party.
      </DisclaimerText>
    );

    const tripAdvisorDisclaimer = (
      <Flex pt={4} flexWrap={'wrap'} gap={1} data-testid="trip-advisor-disclaimer-text">
        <DisclaimerText>Hotel ratings and reviews provided by</DisclaimerText>
        <TripAdvisorLogo src={TRIP_ADVISOR_IMG_BRANDING_URL} alt="Trip-Advisor Logo" role="img" aria-label="Trip-Advisor Logo" />
      </Flex>
    );

    const qffFinalTerms = (
      <DisclaimerText data-testid="qff-final-terms-text">
        {campaignTermsAndConditions ? (
          <Markup content={`^ ${campaignTermsAndConditions} &nbsp;`} />
        ) : showBlueBannerTermsInQffFinalTerms ? (
          <Flex flexDirection="column">
            <Box mb={3}>^ {blueBannerCampaignDisclaimer}</Box>
            <Box>^ {blueBannerAdditionalDisclaimer}</Box>
          </Flex>
        ) : (
          <>
            ^ {qffTerms} {qffDepositPay} {qffFaqs}
          </>
        )}
      </DisclaimerText>
    );

    const blueBannerDisclaimer = (
      <>
        {showBlueBannerDisclaimer && (
          <Flex flexDirection="column">
            <DisclaimerText marginBottom={3} data-testid="blue-banner-disclaimer-text">
              {variables.termsAndConditions}
            </DisclaimerText>
            <DisclaimerText marginBottom={3} data-testid="blue-banner-additional-disclaimer-text">
              {variables.additionalTermsAndConditions}
            </DisclaimerText>
          </Flex>
        )}
      </>
    );

    const depositPayDisclaimer = (
      <DisclaimerText data-testid="deposit-pay-disclaimer-text">
        ^* A minimum 20% deposit, payable using a payment card or using Qantas Points, is required to secure your booking. Deposit payment
        terms will only be permitted for bookings with a free cancellation option, within Australia. Final payment of the balance owing is
        payable 7 days prior to the end of the free cancellation window for the booking, and will be automatically charged to the card
        details provided at the time of booking. Where payment of the balance is not made within the required timeframe, or the payment card
        provided is declined, the booking will be cancelled, and the deposit will be refunded in full. To amend a Deposit Pay booking, the
        original booking will need to be cancelled before the end of the free cancellation window to be eligible for a refund of your
        deposit and a new booking will need to be placed. No changes can be made to a Deposit Pay booking until full payment is received.
        Deposit Pay is available at the discretion of Qantas, and may be removed or revoked at any time. Payment of a deposit indicates
        acceptance of these conditions.
      </DisclaimerText>
    );

    const result: DisclaimerSection[] = [];

    if (POINTS_EARN_ENABLED) {
      result.push({
        id: 'points-disclaimers',
        logo: <DisclaimerItemLogo src={qantasQff} alt="Qantas QFF Logo" role="img" aria-label="Qantas QFF Logo" />,
        items: [
          qffDisclaimer,
          qffFinalTerms,
          qffPointsPay,
          qffBonusPoints,
          savingsDisclaimer,
          yieldifyCampaignDisclaimer,
          localCampaignDisclaimer,
          depositPayDisclaimer,
          blueBannerDisclaimer,
        ],
      });

      result.push({
        id: 'business-rewards-disclaimers',
        logo: (
          <DisclaimerItemLogo
            src={qantasBusinessRewards}
            alt="Qantas Business Reward Logo"
            role="img"
            aria-label="Qantas Business Reward Logo"
          />
        ),
        items: [qbrDisclaimer],
      });
    } else {
      const items = [savingsDisclaimer];
      result.push({ id: 'nopoints-disclaimers', items });
    }

    result.push({
      id: 'misc-disclaimers',
      items: [travelInsuranceTerms, feesDisclaimer, descriptionAndRatingsDisclaimer, tripAdvisorDisclaimer],
    });

    return result;
  }, [
    isConfirmationPage,
    campaignTermsAndConditions,
    showBlueBannerTermsInQffFinalTerms,
    blueBannerCampaignDisclaimer,
    blueBannerAdditionalDisclaimer,
    showBlueBannerDisclaimer,
    variables.termsAndConditions,
    variables.additionalTermsAndConditions,
    emitInteractionEvent,
  ]);

  return (
    <PageBlock py={15} bg="white">
      <Container data-print-style="hidden">
        <Text display="block" fontSize="base" fontWeight="bold" mb={3} mt={5}>
          Important information
        </Text>
        {sections.map((section) => (
          <Flex key={section.id} data-testid={section.id} flexDirection={['column', 'row']} alignItems="flex-start">
            {section.logo}
            <Box>
              {section.items.map((item, i) => (
                <Box key={i} mb={2}>
                  {item}
                </Box>
              ))}
            </Box>
          </Flex>
        ))}
      </Container>
    </PageBlock>
  );
};

export default Disclaimer;
